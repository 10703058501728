import TYPES from '@/types';
import Vue from 'vue';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetAssociatedProductQuery
  from '@/modules/flagship/investor-goal/associated-product/application/queries/get-associated-product-query';
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import GetSearchByCustomerQuery
  from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';
import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';
import CreateLinkSowosWealthCommand
  from '@/modules/flagship/investor-goal/link-sowos-wealth/application/commands/create-link-sowos-wealth-command';

// Domain
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import {
  AssociatedProductEntity,
} from '@/modules/flagship/investor-goal/associated-product/domain/entities/associated-product-entity';
import { InvestorGoal } from '@/modules/flagship/investor-goal/link-sowos-wealth/domain/dtos/investor-goals-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type AssociatedProduct = {
  investor_goal_id: string;
  label: string;
  active_goal_amount: number;
  active_goal_amount_field: string;
  recommend_amount: number;
}

type CustomMessage = {
  investor_goal_id: string;
  message: string;
  class: string;
}

type GoalsAmountInformation = {
  active_goal_amount: number;
  investor_goal_id: string;
  label: string;
  recommend_amount: number;
}

type ActiveRecurrentAmountAmongGoals = {
  label: string;
  recurrent_amount: number;
  tooltip_text: string;
}

export default class StepConfigureContributionsViewModel {
  @Inject(TYPES.GET_ASSOCIATED_PRODUCT_QUERY)
  private readonly get_associated_product_query!: GetAssociatedProductQuery;

  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.CREATE_LINK_SOWOS_WEALTH_COMMAND)
  private readonly create_link_wealth_command!: CreateLinkSowosWealthCommand;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-configure-contributions-wealth';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  private goals_amount_information: Array<GoalsAmountInformation> = [];

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  readonly view!: Vue;

  investor_goals: Array<InvestorGoal> = [];

  investor_goals_dto = {
    total_active_goals_balance: '0.00',
    price_by_unit: '0.00',
    unassigned_balance: '0',
    recurrent_unassigned_amount: '',
    investor_goals: this.investor_goals,
  };

  associated_product_id = '';

  goals_selected: Array<string> = [];

  associated_product: Array<AssociatedProduct> = [];

  is_disabled = false;

  recurrent_assigned_amount = 0;

  max_value_slider = 50000;

  min_value_slider = 500;

  new_recurrent_amount = '0';

  custom_message: Array<CustomMessage> = []

  message = '';

  class = '';

  has_recurring_contributions = true;

  slider_step = 50;

  has_active_goal = false;

  active_recurrent_amount_among_goals: Array<ActiveRecurrentAmountAmongGoals> = [];

  recurrent_unassigned_contribution = 0;

  min_value_required_by_goals: Array<number> = []

  define_contribution_later = false;

  current_recurrent_assigned_amount = 0;

  public constructor(view: Vue) {
    this.view = view;
  }

  // eslint-disable-next-line max-len
  initialize = async (product_id: string, goals_selected: Array<string>, current_investor_goal: any, investor_goals: any) => {
    this.view.$emit('loadingStep', true);
    this.investor_goals_dto = investor_goals;
    this.setInitialInformation(product_id, goals_selected, current_investor_goal);
    await this.loadRecurrentAssignedAmount();
    await this.loadActiveGoals();
    await this.loadAssociatedProduct();
    this.checkIfCustomerHasRecurringContributions();
    this.setRecurrentUnassignedContributionToZeroIfCustomerHasNoActiveGoals();
    this.view.$emit('loadingStep', false);
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  getCustomMessage(investor_goal_id: string) {
    // eslint-disable-next-line max-len
    const index = this.custom_message.findIndex((item) => item.investor_goal_id === investor_goal_id);
    return this.custom_message[index].message;
  }

  getCustomClass(investor_goal_id: string) {
    // eslint-disable-next-line max-len
    const index = this.custom_message.findIndex((item) => item.investor_goal_id === investor_goal_id);
    return this.custom_message[index].class;
  }

  get recurrent_assigned_amount_formatted() {
    return currencyFormat(this.recurrent_assigned_amount);
  }

  // eslint-disable-next-line max-len
  setInitialInformation = (product_id: string, goals_selected: Array<string>, current_investor_goal: any) => {
    this.associated_product_id = product_id;
    this.goals_selected = goals_selected;
    this.goals_amount_information = current_investor_goal;
    this.active_recurrent_amount_among_goals.length = 0;
    this.search_by_customer_dto.associated_product_id = this.associated_product_id;
  }

  loadActiveGoals = async () => {
    try {
      // eslint-disable-next-line max-len
      const active_goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);
      if (active_goals.length) {
        this.has_active_goal = true;
        active_goals.forEach((goal: any) => {
          if (goal.recurrent_assigned_amount > 0) {
            this.active_recurrent_amount_among_goals.push({
              label: (goal.custom_investor_goal) ? goal.custom_investor_goal.goal_name
                : goal.investment_goal_type.label,
              recurrent_amount: goal.recurrent_assigned_amount,
              tooltip_text: (goal.custom_investor_goal) ? this
                .translate('tooltip_custom_goal') : this.translate('tooltip_retirement_goal'),
            });
            this.calculateRecurrentUnassignedContribution(goal.recurrent_assigned_amount);
          }
        });
        this.checkIfRecurrentUnassignedContributionIsMoreThanZero();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_active_goals'));
    }
  }

  addProduct = (product: AssociatedProductEntity, active_goal_amount: number) => {
    const min_value_required = product.monthly_required_amount === 0 ? 0 : this.min_value_slider;
    const monthly_required_amount_rounded = Math.ceil(product.monthly_required_amount);
    const initial_value = (
      monthly_required_amount_rounded < 500 && monthly_required_amount_rounded > 0
        ? this.min_value_slider : monthly_required_amount_rounded
    );
    this.associated_product.push({
      investor_goal_id: product.investor_goal_id,
      label: (product.custom_investor_goal) ? product.custom_investor_goal.goal_name
        : product.investment_goal_type.label,
      active_goal_amount,
      active_goal_amount_field: (active_goal_amount > 0)
        ? (active_goal_amount).toString() : (initial_value)
          .toString(),
      recommend_amount: product.monthly_required_amount,
    });
    this.min_value_required_by_goals.push(min_value_required);
  }

  addCustomMessage = (investor_goal_id: string) => {
    this.custom_message.push({
      investor_goal_id,
      message: '',
      class: '',
    });
  }

  getTotalMinValue = (active_goal_amount: number, monthly_required_amount: number) => {
    if (active_goal_amount > 0) {
      this.is_disabled = false;
      return active_goal_amount;
    }
    return Math.ceil(monthly_required_amount);
  }

  loadAssociatedProduct = async () => {
    try {
      let total_min_value = 0;
      // eslint-disable-next-line max-len
      const associated_products = await this.get_associated_product_query.internalExecute(this.associated_product_id);
      associated_products.forEach((product) => {
        if (this.goals_selected.find(
          (item) => item === product.investor_goal_id,
        )) {
          const active_goal_amount = this.getCurrentGoalAmount(product.investor_goal_id);
          this.addProduct(product, active_goal_amount);
          this.addCustomMessage(product.investor_goal_id);
          total_min_value += this.getTotalMinValue(
            active_goal_amount, product.monthly_required_amount,
          );
        }
      });
      if (this.has_active_goal) {
        total_min_value += this.recurrent_assigned_amount;
      }
      this.new_recurrent_amount = total_min_value.toString();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_associated_product'));
    }
  }

  loadRecurrentAssignedAmount = async () => {
    try {
      // eslint-disable-next-line max-len
      const { amount } = await this.get_allianz_account_query.execute({ customer_id: this.customer_id });
      this.recurrent_assigned_amount = amount || 0;
      this.current_recurrent_assigned_amount = amount || 0;
      this.recurrent_unassigned_contribution = this.recurrent_assigned_amount;
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_recurrent_assigned_amount'));
      }
    }
  }

  calculateRecurrentUnassignedContribution = (recurrent_amount: number) => {
    this.recurrent_unassigned_contribution -= recurrent_amount;
  }

  calculateBalanceAmount = (index: number) => {
    // eslint-disable-next-line max-len
    const minimum_required_value = this.associated_product[index].recommend_amount === 0
      ? this.associated_product[index].recommend_amount : this.min_value_slider;
    let active_goal_amount = (this.associated_product[index].active_goal_amount_field !== '$0 MXN')
      ? Number(parseFloat(this.associated_product[index].active_goal_amount_field.replaceAll(/[^\d.-]/g, '')))
      : minimum_required_value;
    // eslint-disable-next-line max-len
    active_goal_amount = (active_goal_amount < minimum_required_value) ? minimum_required_value : active_goal_amount;
    this.associated_product[index].active_goal_amount = active_goal_amount < this.max_value_slider
      ? active_goal_amount : this.max_value_slider;
    this.associated_product[index].active_goal_amount_field = active_goal_amount
      < this.max_value_slider ? (active_goal_amount).toString()
      : (this.max_value_slider).toString();

    const amount_sliders = this.associated_product.map((obj) => (
      Number(parseFloat(obj.active_goal_amount_field.replaceAll(/[^\d.-]/g, '')))
    ));
    let total_amount_sliders = amount_sliders.reduce(
      (total, currentValue) => total + currentValue, 0,
    );
    if (this.has_active_goal) {
      total_amount_sliders += this.recurrent_assigned_amount;
    }
    this.is_disabled = total_amount_sliders < minimum_required_value
      || total_amount_sliders > this.max_value_slider;

    this.new_recurrent_amount = total_amount_sliders > 0
      ? (total_amount_sliders).toString() : this.recurrent_assigned_amount.toString();

    this.setCustomMessage(
      Number(active_goal_amount),
      this.associated_product[index].recommend_amount,
      this.associated_product[index].investor_goal_id,
    );
  }

  calculateBalanceAmountSlider = (index: number) => {
    // eslint-disable-next-line max-len
    this.associated_product[index].active_goal_amount_field = this.associated_product[index].active_goal_amount < this.max_value_slider
      ? (this.associated_product[index].active_goal_amount).toString()
      : (this.max_value_slider).toString();

    this.calculateBalanceAmount(index);
  }

  // eslint-disable-next-line max-len
  setCustomMessage = (currentAmount: number, recommended_amount: number, investor_goal_id: string) => {
    if (currentAmount < recommended_amount) {
      this.message = this.translate('message_more_time');
      this.class = 'red--text';
    } else if (currentAmount === recommended_amount) {
      this.message = this.translate('message_enough_time');
      this.class = 'sky-blue--text';
    } else {
      this.message = this.translate('message_less_time');
      this.class = 'secondary--text';
    }
    // eslint-disable-next-line max-len
    const index = this.custom_message.findIndex((item) => item.investor_goal_id === investor_goal_id);
    this.custom_message[index].message = this.message;
    this.custom_message[index].class = this.class;
  }

  checkIfCustomerHasRecurringContributions = () => {
    if (!this.recurrent_assigned_amount) {
      this.has_recurring_contributions = false;
    }
  }

  setRecurrentUnassignedContributionToZeroIfCustomerHasNoActiveGoals = () => {
    if (!this.has_active_goal) {
      this.recurrent_unassigned_contribution = 0;
    }
  }

  getCurrentGoalAmount = (investor_goal_id: string) => {
    let active_goal_amount = 0;
    const record = this.goals_amount_information.find(
      (item) => item.investor_goal_id === investor_goal_id,
    );
    if (record) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      active_goal_amount = Number(parseFloat(record.active_goal_amount_field.replaceAll(/[^\d.-]/g, '')));
    }
    return active_goal_amount;
  }

  checkIfRecurrentUnassignedContributionIsMoreThanZero = () => {
    if (this.recurrent_unassigned_contribution > 0) {
      this.active_recurrent_amount_among_goals.push({
        label: this.translate('contribution_unassigned_balance'),
        recurrent_amount: this.recurrent_unassigned_contribution,
        tooltip_text: this.translate('tooltip_unassigned_balance'),
      });
    }
  }

  createPayloadToLinkGoalsWithWealth = () => {
    // eslint-disable-next-line max-len
    this.investor_goals_dto.recurrent_unassigned_amount = this.recurrent_unassigned_contribution.toString();
    this.investor_goals_dto.investor_goals.forEach((investor_goal) => {
      const index = this.associated_product.findIndex(
        (item) => item.investor_goal_id === investor_goal.investor_goal_id,
      );
      if (index !== -1) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        investor_goal.recurrent_assigned_amount = this.associated_product[index].active_goal_amount_field.replaceAll(/[^\d.-]/g, '');
      }
    });
  }

  createLinkSowosWealth = async () => {
    try {
      this.createPayloadToLinkGoalsWithWealth();
      await this.create_link_wealth_command.execute(this.investor_goals_dto);
      const linked_goals_event = new CustomEvent('wealth.linked.goal', { detail: { ...this.investor_goals_dto } });
      window.dispatchEvent(linked_goals_event);
      this.view.$emit('endProcess');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_link_sowos_wealth'));
    }
  }

  loadPriceByUnit = async () => {
    try {
      const funds = await this.search_allianz_account_report_query.execute(this.customer_id);
      const found_founded = funds.find((fund) => fund.found_code === 'SWSRFP');
      if (found_founded) {
        const price_by_unit = found_founded.units_value || 0;
        this.investor_goals_dto.price_by_unit = price_by_unit.toString();
        await this.createLinkSowosWealth();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_price_by_unit'));
    }
  }

  defineContributionLaterChange = () => {
    this.view.$emit('changeDefineContributionLater', this.define_contribution_later);
    if (this.define_contribution_later) {
      this.associated_product[0].active_goal_amount_field = '0.00';
    } else {
      this.calculateBalanceAmount(0);
    }
  }

  sendNewRecurrentAssignedAmount = async () => {
    const new_recurrent_amount = this.new_recurrent_amount.replaceAll(/[^\d.-]/g, '');
    this.view.$emit('changeGoalsAmountInformation', this.associated_product);
    if (!this.define_contribution_later) {
      this.view.$emit('changeRecurrentAmount', new_recurrent_amount);
      this.view.$emit('changeRecurrentUnassignedAmount', this.recurrent_unassigned_contribution);
      this.view.$emit('nextStep');
    } else {
      await this.loadPriceByUnit();
    }
  }

  goToPrevStep = () => {
    this.view.$emit('prevStep');
  }
}
