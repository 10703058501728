














































































































































































































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';

import StepConfigureContributionsViewModel
  from '@/vue-app/view-models/components/goals-dashboard/add-goals/link/step-configure-contributions-view-model';

@Component({
  name: 'StepConfigureContributions',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class StepConfigureContributions extends Vue {
  @PropSync('investorGoals', { type: Object, required: true })
  investor_goals!: Record<string, any>;

  @PropSync('associatedProductId', { type: String, required: true })
  associated_product_id!: string;

  @PropSync('goalsSelected', { type: Array, required: true })
  goals_selected!: Array<string>;

  @PropSync('goalsAmountInformation', { type: Array, required: true })
  goals_amount_information!: Array<object>;

  step_configure_contributions_wealth_model = Vue.observable(
    new StepConfigureContributionsViewModel(this),
  );

  created() {
    this.step_configure_contributions_wealth_model.initialize(
      this.associated_product_id, this.goals_selected, this.goals_amount_information,
      this.investor_goals,
    );
  }
}
